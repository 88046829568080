let config = {
  api: {
    url: '',
  },
  authentication: {
    authority: '',
    redirectUrl: '',
    clientId: '',
    responseType: '',
    scope: '',
    postLogoutRedirectUrl: '',
    silentRenewRedirectUrl: '',
  },
  signalR: {
    hubs: {
      claims: '',
      bureauclaims: '',
      emailClaims: '',
      alerts: '',
      sanctions: '',
      notifications: '',
    },
  },
  bureauUrl: '',
  sanctionsUrl: '',
}

export const getConfigurationData = () => {
  const tenantName = import.meta.env.VITE_AAD_TENANT_NAME
  const clientId = import.meta.env.VITE_AAD_CLIENT_ID
  const baseUrl = import.meta.env.VITE_BASE_URL
  const config = {
    api: {
      url: import.meta.env.VITE_API_URL,
    },
    authentication: {
      authority: import.meta.env.VITE_AAD_PROVIDER_URL,
      redirectUrl: `${baseUrl}/callback-new`,
      clientId: clientId,
      responseType: 'code',
      scope: `openid \
        profile \
        https://${tenantName}/${clientId}/claims \
        https://${tenantName}/${clientId}/gateway \
        https://${tenantName}/${clientId}/orgstructure \
        https://${tenantName}/${clientId}/reporting \
        https://${tenantName}/${clientId}/audittrail \
        https://${tenantName}/${clientId}/configurations \
        https://${tenantName}/${clientId}/bureau-claims \
        https://${tenantName}/${clientId}/documents`,
      postLogoutRedirectUrl: `${baseUrl}/logout`,
      silentRenewRedirectUrl: `${baseUrl}/silent-renew`,
    },
    signalR: {
      hubs: {
        claims: import.meta.env.VITE_SIGNALR_HUB_CLAIMS,
        bureauclaims: import.meta.env.VITE_SIGNALR_HUB_BUREAUCLAIMS,
        emailClaims: import.meta.env.VITE_SIGNALR_HUB_EMAILCLAIMS,
        alerts: import.meta.env.VITE_SIGNALR_HUB_ALERTS,
        sanctions: import.meta.env.VITE_SIGNALR_HUB_SANCTIONS,
        notifications: import.meta.env.VITE_SIGNALR_HUB_NOTIFICATIONS,
      },
    },
    bureauUrl: `${import.meta.env.VITE_REMOTE_BUREAU}`,
    sanctionsUrl: `${import.meta.env.VITE_REMOTE_SANCTIONS}`,
  }

  return {
    config,
  }
}

export const getConfig = () => {
  return config
}

export const setConfig = (configuration) => {
  config = configuration
}
