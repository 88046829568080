<script setup>
// @ts-check
import { onMounted, onUnmounted } from 'vue'

const emits = defineEmits({ idle: null })

const props = defineProps({
  interval: { type: Number, default: 60 },
  duration: { type: Number, default: 120 },
})

let isActive = true

const updateTimeInterval = setInterval(async () => {
  await refreshSessionTime()
}, props.interval * 1000)

onMounted(() => {
  const curr_time = new Date().getTime()
  sessionStorage.setItem('session-time', String(curr_time))

  window.addEventListener('focus', onWindowFocus)
  window.addEventListener('blur', onWindowBlur)
})

onUnmounted(() => {
  window.removeEventListener('focus', onWindowFocus)
  window.removeEventListener('blur', onWindowBlur)
  sessionStorage.removeItem('session-time')
  clearInterval(updateTimeInterval)
})

const refreshSessionTime = async () => {
  const curr_time = new Date().getTime()
  const last_time = sessionStorage.getItem('session-time')
  const diff = curr_time - Number(last_time)

  // TODO: remove after bug 317715 retest
  console.log(
    '[refreshSessionTime] ',
    'diff: ',
    diff,
    'sessionExpired: ',
    diff > props.duration * 1000
  )

  if (diff > props.duration * 1000) {
    // TODO: remove after bug 317715 retest
    console.log('emits idle')
    emits('idle')
  } else if (isActive) {
    sessionStorage.setItem('session-time', String(curr_time))
  }
}

const onWindowFocus = () => {
  isActive = true
  const curr_time = new Date().getTime()
  const last_time = sessionStorage.getItem('session-time')
  const diff = curr_time - Number(last_time)

  // TODO: remove after bug 317715 retest
  console.log(
    '[onWindowFocus]',
    'diff: ',
    diff,
    'sessionExpired: ',
    diff > props.duration * 1000
  )

  if (diff > props.duration * 1000) {
    // TODO: remove after bug 317715 retest
    console.log('emits idle')
    emits('idle')
  }
}

const onWindowBlur = () => {
  // TODO: remove after bug 317715 retest
  console.log('[onWindowBlur]')

  isActive = false
  const curr_time = new Date().getTime()
  sessionStorage.setItem('session-time', String(curr_time))
}
</script>
