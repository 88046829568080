import addTooltipDirective from './tooltip/'

/**
 * A function to add directives to a Vue application.
 * @param {import('vue').App} app - The Vue application instance.
 */
const addDirectives = (app) => {
  addTooltipDirective(app)
}

export default addDirectives
