export const Urls = {
  app: {
    initialize: '/app/initialize',
  },
  authorityLimitsForCob: '/authority-limits',
  positions: {
    update: (positionId) => `/positions/${positionId}`,
    delete: (positionId) => `/positions/${positionId}`,
    create: 'positions',
    positions: 'positions',
    positionById: (id) => `positions/${id}`,
    positionTypes: 'position-types',
    child: (parentId) => `/positions/${parentId}/child`,
  },
  tasks: {
    managerInbox: '/tasks/manager-inbox',
    claimsInbox: 'bureau/tasks/claims-inbox',
    outstandingInbox: 'tasks/outstanding-inbox',
    takeOwnership: 'bureau/tasks/take-ownership',
    reassign: `/non-bureau/tasks/reassign`,
    userTasks: `non-bureau/tasks/user-tasks`,
    hasUserOutstandingTasks: (userId) =>
      `non-bureau/tasks/user/${userId}/has-outstanding`,
    post: (claimId) => `non-bureau/claim/${claimId}/task`,
    delete: (claimId, taskId) => `non-bureau/claim/${claimId}/task/${taskId}`,
    update: (claimId, taskId) => `non-bureau/claim/${claimId}/task/${taskId}`,
    complete: (claimId) => `non-bureau/claim/${claimId}/task/complete`,
    approve: (claimId, taskId) => `non-bureau/claim/${claimId}/task/${taskId}/approve`,
    reject: (claimId, taskId) => `non-bureau/claim/${claimId}/task/${taskId}/reject`,
    sendForManagerApproval: (claimId) =>
      `non-bureau/claim/${claimId}/tasks/send-for-manager-approval`,
    approver: (claimId, taskId) =>
      `non-bureau/claim/${claimId}/task/${taskId}/task-approver`,
    comments: (claimId) => `non-bureau/claim/${claimId}/task/comments`,
    taskComments: (claimId, taskId) =>
      `non-bureau/claim/${claimId}/task/${taskId}/comments`,
  },
  emails: {
    tasks: '/email-claims/tasks',
    outstanding: '/email-claims/tasks/outstanding-inbox',
    manager: '/email-claims/tasks/manager-inbox',
    details: '/email-claims/tasks/email-details',
    childrenEmails: '/email-claims/tasks/children-emails',
    takeOwnership: '/email-claims/tasks/take-ownership',
    downloadAttachment: ({ emailId, attachmentId }) =>
      `/email-claims/tasks/${emailId}/email-attachment/${attachmentId}`,
    downloadImages: (emailId) => `/email-claims/tasks/${emailId}/email-images`,
    clear: '/email-claims/tasks/clear',
    reassign: '/email-claims/tasks/reassign',
    addComment: 'email-claims/tasks/comment',
    getComments: (emailId) => `/email-claims/tasks/${emailId}/comments`,
    assign: '/email-claims/tasks/assign',
    sendAReply: ({ emailId }) => `/email-claims/tasks/${emailId}/reply`,
    mailbox: {
      subscriptions: '/email-claims/mailbox/subscriptions',
    },
  },
  emailTasks: {
    attachToClaims: '/non-bureau/email-tasks/attach-to-claims',
    attachedClaims: '/non-bureau/email-tasks/attached-claims',
    comment: '/non-bureau/email-tasks/comment',
  },
  currencies: '/currencies',
  domain: {
    types: '/domain-types',
    dataByType: (domainTypeId) => `claims/domain-data/${domainTypeId}`,
    dataByTypeAdmin: (domainTypeId) => `domain-data/${domainTypeId}`,
    data: '/domain-data',
  },
  policies: (reference) => `/policies/${reference}`,
  participants: {
    all: 'non-bureau/participants/names',
    byId: (id) => `non-bureau/participants/${id}`,
    bankDetails: (participantId) =>
      `non-bureau/participants/${participantId}/bank-details`,
    assign: (claimId) => `non-bureau/claim/${claimId}/assign-participant`,
    assignContactsToParticipant: (claimId) =>
      `non-bureau/claim/${claimId}/assign-contacts-to-participant`,
  },
  payments: {
    email: (paymentId, claimId) =>
      `non-bureau/claim/${claimId}/payment/${paymentId}/email`,
    newEmail: (claimId) => `non-bureau/claim/${claimId}/payment/email`,
    approve: (claimId, paymentId) =>
      `non-bureau/claim/${claimId}/payment/${paymentId}/approve`,
    reject: (claimId, paymentId) =>
      `non-bureau/claim/${claimId}/payment/${paymentId}/reject`,
    confirmRejection: (claimId, paymentId) =>
      `non-bureau/claim/${claimId}/payment/${paymentId}/confirm-rejection`,
    confirmComplianceRejection: (claimId, paymentId) =>
      `non-bureau/claim/${claimId}/payment/${paymentId}/confirm-compliance-rejection`,
    getDuplicatePayments: (claimId) =>
      `non-bureau/claim/${claimId}/payment/get-duplicate-payments`,
    payment: (claimId) => `/non-bureau/claim/${claimId}/payment`,
    reissuePayment: (claimId, paymentId) =>
      `/non-bureau/claim/${claimId}/payment/${paymentId}/reissue-payment`,
    reprocessPayment: (claimId, paymentId) =>
      `/non-bureau/claim/${claimId}/payment/${paymentId}/reprocess-payment`,
    document: ({ claimId, paymentId, documentId }) =>
      `/non-bureau/claim/${claimId}/payment/${paymentId}/document/${documentId}`,
  },
  search: {
    search: '/claim-search',
    count: '/claim-search/count',
    bureauSearch: 'bureau/claim-search',
  },
  policySearch: '/policy-search',
  notes: {
    note: (claimId) => `/non-bureau/claim/${claimId}/notes`,
    notes: (claimId, noteId) => `/non-bureau/claim/${claimId}/notes/${noteId}`,
  },
  dashboards: {
    completedTasks: '/claims/dashboards/completed-tasks',
    newClaims: '/claims/dashboards/new-claims-today',
    openClaims: {
      all: '/claims/dashboards/open-claims',
      noActivity: '/claims/dashboards/open-claims/no-activity',
      outstandingReserves: '/claims/dashboards/open-claims/outstanding-reserves',
      zeroOutstandingReserves: '/claims/dashboards/open-claims/zero-outstanding-reserves',
    },
  },
  claims: {
    nonBureau: '/non-bureau/claim',
    runTriage: '/non-bureau/claim/run-triage',
    update: (claimId) => `/non-bureau/claim/${claimId}`,
    close: (claimId) => `non-bureau/claim/${claimId}/close`,
    reopen: (claimId) => `non-bureau/claim/${claimId}/reopen`,
    activeSubclaimTypes: 'claims/subclaim-types/active',
    accessCheck: (claimId) => `non-bureau/claim/${claimId}/access-check`,
    approvalLimits: (claimId) => `/non-bureau/claim/${claimId}/approval-limits`,
    overview: (claimId) => `non-bureau/claim/${claimId}/overview`,
    summary: (claimId) => `aggregate/non-bureau/claim/${claimId}/summary`,
    logs: (claimUuid) => `claim/${claimUuid}/activitylogs`,
    reserves: (claimId) => `non-bureau/claim/${claimId}/reserves`,
    financialOverview: (claimId) => `non-bureau/claim/${claimId}/financial-overview`,
    financialTimeline: (claimId) => `non-bureau/claim/${claimId}/financial-timeline`,
    roleBasedPerformers: (claimId) => `non-bureau/claim/${claimId}/role-based-performers`,
    participants: (claimId) => `non-bureau/claim/${claimId}/participants`,
    updateRoleBasedPerformer: (claimId) =>
      `non-bureau/claim/${claimId}/update-role-based-performer`,
    subclaims: (claimId) => `/non-bureau/claim/${claimId}/subclaims`,
    options: (claimId) => `/non-bureau/claim/${claimId}/options`,
    notes: (claimId) => `/non-bureau/claim/${claimId}/notes`,
    subClaimItems: (claimId) => `/non-bureau/claim/${claimId}/subclaim-items`,
    recoveries: (claimId) => `/non-bureau/claim/${claimId}/recoveries`,
    recover: (claimId) => `/non-bureau/claim/${claimId}/recover`,
    writeOff: (claimId) => `/non-bureau/claim/${claimId}/write-off`,
    reservesHistory: (claimId) => `/non-bureau/claim/${claimId}/reserves-history`,
    recoveryHistory: (claimId) => `/non-bureau/claim/${claimId}/recovery-history`,
    subclaimNames: (claimId) => `non-bureau/claim/${claimId}/subclaim-names`,
    paymentOverview: (claimId) => `non-bureau/claim/${claimId}/payment-overview`,
    hasOpenTasks: (claimId) => `non-bureau/claim/${claimId}/has-open-tasks`,
    canClose: (claimId) => `non-bureau/claim/${claimId}/can-close`,
    tasks: (claimId) => `non-bureau/claim/${claimId}/tasks`,
    duplicate: 'non-bureau/claim/duplicate',
    policyOverview: (claimId) => `non-bureau/claim/${claimId}/policy-overview`,
    policyUpdatedDate: (claimId) => `non-bureau/claim/${claimId}/policy-updated-date`,
    refreshClaimPolicy: (claimId) => `non-bureau/claim/${claimId}/refresh-policy`,
    alerts: (claimId) => `non-bureau/claim/${claimId}/alerts`,
    emails: (claimId) => `non-bureau/claim/${claimId}/emails`,
    canPay: (claimId) => `non-bureau/claim/${claimId}/can-pay`,
    emailTemplates: (claimId) => `non-bureau/claim/${claimId}/email-form`,
  },
  documents: {
    getAllowedMimedTypes: '/documents/allowed-mime-types',
    upload: '/documents/upload',
    download: ({ id, claimId, claimReference, claimUuid }) =>
      `documents/download/${id}?claimId=${claimId}&claimReference=${claimReference}&claimUuid=${claimUuid}`,
    patch: (documentId) => `documents/${documentId}`,
    update: (documentId) => `documents/${documentId}`,
    policyDocuments: (policyReference) => `${policyReference}/documents`,
    claimDocuments: (claimUuid) => `claim/${claimUuid}/documents`,
  },
  subclaims: {
    status: (claimId, subclaimId) =>
      `/non-bureau/claim/${claimId}/subclaim/${subclaimId}/status`,
  },
  subclaimTypes: {
    getActive: `/subclaim-types/active`,
  },
  classesOfBusiness: {
    get: '/claims/classes-of-business',
    subclaimTypes: (classOfBusinessId) =>
      `/claims/classes-of-business/${classOfBusinessId}/subclaim-types`,
  },
  users: {
    getActive: 'users/active',
    permissions: (classOfBusinesses) => {
      var classOfBusinessArray = ''

      classOfBusinesses?.forEach((classOfBusiness, index) => {
        classOfBusinessArray += `${
          index === 0 ? '?' : '&'
        }classOfBusinesses=${classOfBusiness}`
      })

      return `/Users/permission/classOfBusinesses${classOfBusinessArray}`
    },
    managers: 'users/managers',
    names: 'users/names',
    holidays: 'users/holidays',
    me: 'users/me',
    managersWithPermissions: (userId, classOfBusiness) =>
      `/Users/managers-with-permission?UserIds=${userId}&ClassOfBusiness=${classOfBusiness}`,
    hasReportees: 'users/me/has-reportees',
  },
  permissions: {
    user: 'user/permissions',
    all: 'permissions',
  },
  configuration: {
    feature: '/features',
    featureById: (id) => `/features/${id}`,
    all: '/system-configuration',
    system: (id) => `/system-configuration/${id}`,
    holidays: '/holidays',
    holidayById: (id) => `/holidays/${id}`,
    roles: {
      get: '/config/roles',
    },
  },
  admin: {
    users: {
      get: '/users',
      create: '/users',
      update: (userId) => `/users/${userId}`,
    },
    roles: {
      getActive: '/roles/active',
      get: '/roles',
      create: '/roles',
      update: (roleId) => `/roles/${roleId}`,
    },
    approvalLimits: '/approval-limits',
    authorityLimitTypes: '/authority-limit-types',
    emailTemplates: {
      all: '/email-templates',
      create: '/email-templates',
      update: '/email-templates',
      delete: (emailTemplateId) => `/email-templates/${emailTemplateId}`,
    },
    subclaimTypes: '/subclaim-types',
    subclaimItemTypes: '/subclaim-item-types',
    participants: 'non-bureau/participants',
    participantsExperts: {
      all: 'participants',
      create: 'participants',
      update: (id) => `participants/${id}`,
      bankDetailsCreate: (id) => `participants/${id}/bank-details`,
      bankDetailsGet: (id) => `participants/${id}/bank-details`,
      contactDetails: {
        create: (id) => `participants/${id}/contact-details`,
        update: (id) => `participants/${id}/contact-details`,
      },
      kyribaBeneficiaries: 'kyriba-beneficiaries',
      externalBeneficiaries: 'external-beneficiaries',
      getExternalBeneficiaries: (id) => `external-beneficiaries/${id}/participant`,
    },
    payments: {
      all: 'payments',
      create: 'payments',
      update: 'payments',
      addRules: (id) => `payments/rule/${id}`,
      deleteRules: (id) => `payments/${id}/rules`,
      activeForReport: (includeInPaymentReport) =>
        `payments/report/?includeInPaymentReport=${includeInPaymentReport}`,
      kyribaDebitAccountDetails: 'kyriba-debit-account-details',
      externalBeneficiaryBankDetails: (accountCode) =>
        `kyriba-beneficiaries/bank-details/${accountCode}`,
    },
    options: {
      all: 'options',
      allIncludedInClaimReport: 'options/report?isIncludedInClaimReport=true',
      allIncludedInClaimSearch: 'options/report?isIncludedInClaimSearch=true',
      addRules: (id) => `options/rule/${id}`,
      deleteRule: `options/rule`,
    },
    fields: '/fields',
    classesOfBusiness: {
      submit: '/classes-of-business',
      get: '/classes-of-business',
      delete: (classOfBusinessId) => `classes-of-business/${classOfBusinessId}`,
      subclaimTypes: (classOfBusinessId) =>
        `classes-of-business/${classOfBusinessId}/subclaim-types`,
      groups: '/classes-of-business/groups',
      deleteGroup: (classOfBusinessGroupId) =>
        `classes-of-business/groups/${classOfBusinessGroupId}`,
    },
    workflow: {
      all: '/workflow',
      delete: (workflowId) => `workflow/${workflowId}`,
    },
    triage: {
      all: '/triage',
      delete: (triageId) => `triage/${triageId}`,
    },
    alert: {
      all: '/alert',
      delete: (alertId) => `alert/${alertId}`,
    },
  },
  reporting: {
    audit: {
      claimEventTypes: 'reporting/auditing/event-types?eventType=0',
      adminEventTypes: 'reporting/auditing/event-types?eventType=1',
      accessEventTypes: 'reporting/auditing/event-types?eventType=2',
      emailEventTypes: 'reporting/auditing/event-types?eventType=3',
      claim: `reporting/auditing/claim`,
      admin: 'reporting/auditing/admin',
      access: 'reporting/auditing/access',
      email: 'reporting/auditing/email',
    },
    financial: {
      item: '/reporting/financial/item',
      claim: '/reporting/financial/claim',
      subclaim: '/reporting/financial/subclaim',
    },
    claim: 'reporting/claim',
    task: 'reporting/task',
    emailTask: 'reporting/email-task',
    payment: `reporting/payment`,
    watermark: '/watermark',
  },
  configurations: {
    rag: 'rag',
  },
  configurationStore: {
    initialize: `/aggregate/configuration-store/initialize`,
  },
  accessLog: {
    post: 'accesslog',
  },
  mailiing: '/mailing/send-with-attachments',
  rules: {
    options: '/rules-engine/options/validate',
    emailTemplates: '/rules-engine/email-templates/replace',
    alerts: 'rules-engine/alerts/trigger',
    paymentFields: 'rules-engine/payments/fields',
  },
  notifications: {
    post: '/notification',
    markAsRead: '/notification/mark-as-read',
  },
  sanctions: {
    bulkCheck: (claimUuid) => `/sanction-beneficiary/${claimUuid}/bulk-check`,
  },
}
