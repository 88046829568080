<script setup>
import { computed, ref, onMounted } from 'vue'
import { RouterLink } from 'vue-router'

const logoImageSrc = '/images/layout/iconLogo.png'
const isVisible = ref(false)

const props = defineProps({
  linkTo: { type: String },
})

const isExternalLink = computed(
  () => typeof props.linkTo === 'string' && props.linkTo.startsWith('http')
)
onMounted(async () => {
  setTimeout(() => {
    isVisible.value = true
  }, 100)
})
</script>
<template>
  <img
    id="logo__image"
    v-if="!props.linkTo && isVisible"
    :src="logoImageSrc"
    alt="DOCOSoft"
    style="height: 42px"
  />
  <a
    id="logo__external-link"
    v-else-if="isExternalLink && isVisible"
    :href="props.linkTo"
    target="_blank"
  >
    <img id="logo__image" :src="logoImageSrc" alt="DOCOSoft" style="height: 42px" />
  </a>
  <RouterLink id="logo__internal-link" v-else-if="isVisible" :to="{ name: props.linkTo }">
    <img id="logo__image" :src="logoImageSrc" alt="DOCOSoft" style="height: 42px" />
  </RouterLink>
</template>
