import { defineStore, acceptHMRUpdate } from 'pinia'
import { useRequest } from '@/services/api/useRequest'
import { Urls } from '@/services/api/Urls'
import { Stores } from '@/stores/Stores'
import { useAccessLogService } from '@/services/api/useAccessLogService'

export const useNotificationsStore = defineStore(Stores.NotificationsStore, {
  state: () => ({
    readNotifications: [],
    unreadNotifications: [],
    start: 0,
    end: 20,
    pageSize: 20,
    disabled: true,
    readTotalCount: 0,
  }),
  getters: {
    getReadNotifications: (state) => state.readNotifications.slice(0, state.end),
    getUnreadNotifications: (state) => state.unreadNotifications,
  },
  actions: {
    async fetchUnreadNotifications() {
      const { post } = useRequest()
      const { logEvent } = useAccessLogService()

      const { notifications } = await post(Urls.notifications.post, {
        read: false,
      })

      this.unreadNotifications = notifications.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      )

      logEvent({
        eventName: 'Notifications viewed',
      })
    },
    async fetchReadNotifications() {
      const { post } = useRequest()
      const { logEvent } = useAccessLogService()

      const { notifications, totalResultCount } = await post(Urls.notifications.post, {
        start: this.start,
        end: this.end,

        read: true,
      })
      this.readNotifications = notifications
      this.readTotalCount = totalResultCount
      logEvent({
        eventName: 'Notifications viewed',
      })
    },
    async fetchNextRead() {
      const { post } = useRequest()
      this.start += this.pageSize
      this.end += this.pageSize

      if (this.start >= this.readTotalCount) {
        return
      }

      const { notifications } = await post(Urls.notifications.post, {
        start: this.start,
        end: this.end,
        read: true,
      })

      if (!notifications && notifications.length < 1) return

      this.readNotifications = this.readNotifications.concat(notifications)
    },
    /**
     * Send notifications to be marked as read
     * @param {number[]} notificationIDs IDs of the notifications to be marked as read
     * @returns {Promise<void>}
     */
    async markAsRead(notificationIDs) {
      this.disabled = true

      if (notificationIDs.length > 0) {
        const { post } = useRequest()
        await post(Urls.notifications.markAsRead, {
          ids: notificationIDs,
          all: false,
        })
      }
    },
    async markAllAsRead() {
      const { post } = useRequest()
      await post(Urls.notifications.markAsRead, {
        ids: [],
        all: true,
      })
    },
    reset() {
      this.readNotifications = []
      this.unreadNotifications = []
      this.start = 0
      this.end = 20
      this.readTotalCount = 0
    },
    enable() {
      this.disabled = false
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}
