import { RouteItems, RouteNames } from '@/router/routes'
import { i18n } from '@/plugins/useVueI18n'

/**
 *
 * @param featureConfigurationNames
 * @param systemConfigurationNames
 */
export function useMenuItems(featureConfigurationNames, systemConfigurationNames) {
  const { t } = i18n.global

  const getMenuItem = (itemName) => {
    return getMenuItems().find((x) => x.name === itemName)
  }

  const getMenuItems = () => {
    {
      const menuItems = [
        {
          title: t('common.userAdministration'),
          parent: t('common.administration'),
          children: [
            {
              title: t('common.user'),
              to: '/users-admin',
              icon: 'user',
              parent: RouteItems.Administration.name,
              name: RouteNames.UserAdministration,
            },
            {
              title: t('common.roles'),
              to: '/roles-admin',
              icon: 'users',
              parent: RouteItems.Administration.name,
              name: RouteNames.RoleAdministration,
            },
            {
              title: t('common.organizationStructure'),
              to: '/org-structure',
              icon: 'layout',
              parent: RouteItems.Administration.name,
              name: RouteNames.OrgStructure,
            },
          ],
        },
        {
          title: t('common.miscellaneousAdministration'),
          parent: t('common.administration'),
          children: [
            {
              title: t('common.claimsMasterData'),
              to: '/claims-master-data-admin',
              icon: 'layers',
              parent: RouteItems.Administration.name,
              name: RouteNames.ClaimsMasterDataAdministration,
            },
            {
              title: t('common.subclaim'),
              to: '/subclaim-types',
              icon: 'layers',
              parent: RouteItems.Administration.name,
              name: RouteNames.SubclaimsAdministration,
            },
            {
              title: t('common.cob'),
              to: '/class-of-business-admin',
              icon: 'layers',
              parent: RouteItems.Administration.name,
              name: RouteNames.ClassOfBusinessAdministration,
            },
            {
              title: t('common.cobGroup'),
              to: '/class-of-business-group',
              icon: 'layers',
              parent: RouteItems.Administration.name,
              name: RouteNames.ClassOfBusinessGroupAdministration,
            },
            {
              title: t('common.approvalLimits'),
              to: '/approval-limits-admin',
              icon: 'clock',
              parent: RouteItems.Administration.name,
              name: RouteNames.ApprovalLimitsAdministration,
            },
            {
              title: t('common.taskRAG'),
              to: '/rag-admin',
              icon: 'clock',
              parent: RouteItems.Administration.name,
              name: RouteNames.RagStatus,
            },
            {
              title: t('common.holiday'),
              to: '/holidays-admin',
              icon: 'calendar',
              parent: RouteItems.Administration.name,
              name: RouteNames.HolidayAdministration,
            },
            {
              title: t('common.emailTemplates'),
              to: '/email-template-admin',
              icon: 'tabler:template',
              parent: RouteItems.Administration.name,
              name: RouteNames.EmailTemplatesAdministration,
            },
            {
              title: t('common.participants'),
              to: '/participants-experts-admin',
              icon: 'users',
              parent: RouteItems.Administration.name,
              name: RouteNames.ParticipantsExpertsAdministration,
            },
          ],
        },
        {
          title: t('common.rulesAdministration'),
          parent: t('common.administration'),
          children: [
            {
              title: t('common.alerts'),
              to: '/alert-admin',
              icon: 'repeat',
              parent: RouteItems.Administration.name,
              name: RouteNames.AlertAdministration,
            },
            {
              title: t('common.options'),
              to: '/options-configuration',
              icon: 'layers',
              parent: RouteItems.Administration.name,
              name: RouteNames.OptionsConfigurationAdministration,
            },
            {
              title: t('common.payment'),
              to: '/payments-admin',
              icon: 'carbon:money',
              parent: RouteItems.Administration.name,
              name: RouteNames.PaymentsAdministration,
            },
            {
              title: t('common.triage'),
              to: '/triage-admin',
              icon: 'repeat',
              parent: RouteItems.Administration.name,
              name: RouteNames.TriageAdministration,
            },
            {
              title: t('common.workflow'),
              to: '/workflow-admin',
              icon: 'repeat',
              parent: RouteItems.Administration.name,
              name: RouteNames.WorkflowAdministration,
            },
          ],
        },
        {
          title: t('common.claimReport'),
          to: '/reporting/claim',
          icon: 'align-justify',
          parent: RouteItems.Reporting.name,
          name: RouteNames.ClaimReport,
        },
        {
          title: t('common.financialReports'),
          to: '/reporting/financial-reports',
          icon: 'align-justify',
          parent: RouteItems.Reporting.name,
          name: RouteNames.FinancialReports,
        },
        {
          title: t('common.taskReport'),
          to: '/reporting/task',
          icon: 'align-justify',
          parent: RouteItems.Reporting.name,
          name: RouteNames.TaskReport,
        },
        {
          title: t('common.paymentReport'),
          to: '/reporting/payment',
          icon: 'align-justify',
          parent: RouteItems.Reporting.name,
          name: RouteNames.Payment,
        },
        {
          title: t('common.emailTaskDetailsReport'),
          to: '/reporting/email-task',
          icon: 'align-justify',
          parent: RouteItems.Reporting.name,
          name: RouteNames.EmailTaskReport,
        },
        {
          title: t('common.auditReports'),
          to: '/reporting/audit',
          icon: 'align-justify',
          parent: RouteItems.Reporting.name,
          name: RouteNames.Audit,
        },
        {
          title: t('common.featureConfiguration'),
          to: '/feature-configuration',
          icon: 'tool',
          parent: RouteItems.Configuration.name,
          name: RouteNames.FeatureConfiguration,
          children: featureConfigurationNames.map((x) => ({
            title: x.label,
            id: x.id,
            icon: 'tool',
            to: '/feature-configuration/' + x.id,
            name: RouteNames.FeatureConfiguration,
          })),
        },
        {
          title: t('common.systemConfiguration'),
          to: '/system-configuration',
          icon: 'tool',
          parent: RouteItems.Configuration.name,
          name: RouteNames.SystemConfiguration,
          children: systemConfigurationNames.map((x) => ({
            title: x.label,
            id: x.id,
            icon: 'tool',
            to: '/system-configuration/' + x.id,
            name: RouteNames.SystemConfiguration,
          })),
        },
      ]
      return menuItems
    }
  }

  return {
    getMenuItems,
    getMenuItem,
  }
}
