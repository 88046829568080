<script setup>
import { onMounted, onBeforeUnmount, watch } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { ModalsContainer } from 'vue-final-modal'
import { RequestSpinner } from '@docosoft/docosoft-ui-components'
import AppLayout from '@/components/templates/App.vue'
import { useApplicationStoreNotPersistant } from '@/stores/ApplicationStoreNotPersistant'
import { useApplicationStore, LoginStatus } from '@/stores/ApplicationStore'
import { useConfigurationStore } from '@/stores/configuration/ConfigurationStore'
import { useLogger } from '@/services/useLogger'
import SessionTimeTracker from './utils/SessionTimeTracker.vue'
import { useNotifications } from '@/components/components/notifications/useNotifications'
import { useNotifications as useNotifications2 } from '@/components/pages/non-bureau/notifications/useNotifications'

const { navigateToClaim } = useNotifications2()

const route = useRoute()
const applicationStoreNotPersistant = useApplicationStoreNotPersistant()
const applicationStore = useApplicationStore()
const configurationStore = useConfigurationStore()
const { initNotifications, stopNotifications } = useNotifications(navigateToClaim)
const idleTimeoutSeconds = 1200 // 20 min

/**
 * Handles Vite preload error event.
 * New deployment may delete assets from previous deployments, causing import errors
 * for user who visited the site before the new deployment. These errors occur when
 * outdated assets on the user's device attempt to import deleted chunks.
 * @param {Event} event Vite preload error event
 */
const handleVitePreloadError = (event) => {
  // To not throw the error.
  event.preventDefault()

  const { log } = useLogger()
  log('handleVitePreloadError: ', event.payload?.message, event)

  // Refresh the page, when failed to fetch dynamically imported module.
  window.location.reload()
}

onMounted(async () => {
  window.addEventListener('vite:preloadError', handleVitePreloadError)

  const log = useLogger()
  const router = useRouter()
  const requestId = applicationStoreNotPersistant.setLoadingApplication()
  const status = await applicationStore.initApplication({ router })

  if (status === LoginStatus.DONE) {
    try {
      await configurationStore.setupFeaturesConfiguration()
    } finally {
      applicationStoreNotPersistant.setApplicationReady(requestId)
    }
  } else {
    log.info('Session expired')
    applicationStoreNotPersistant.setApplicationReady(requestId)
  }
})

onBeforeUnmount(async () => {
  window.removeEventListener('vite:preloadError', handleVitePreloadError)
  await stopNotifications()
})

watch(
  () => applicationStoreNotPersistant.isApplicationReady,
  async () => {
    if (applicationStoreNotPersistant.isApplicationReady) {
      await initNotifications()
    }
  }
)

const onidle = async () => {
  // TODO: remove after bug 317715 retest
  console.log('[ON IDLE]')
  applicationStore.setSessionExpired()
  await applicationStore.logout(true)
}
</script>
<template>
  <AppLayout>
    <template v-if="applicationStoreNotPersistant.isApplicationReady">
      <RouterView v-slot="{ Component }">
        <Transition mode="out-in">
          <component :is="Component" :key="route.path" />
        </Transition>
      </RouterView>
    </template>
  </AppLayout>

  <ModalsContainer />
  <RequestSpinner
    :visible="applicationStoreNotPersistant.isRequestRunning"
    :show-spinner="applicationStoreNotPersistant.shouldShowRequestSpinner"
  />

  <v-idle
    @idle="onidle"
    :wait="5"
    :duration="idleTimeoutSeconds"
    :events="['keypress', 'mousedown']"
  />
  <SessionTimeTracker @idle="onidle" :duration="idleTimeoutSeconds" />
</template>
