import { ref, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useMenuItems } from '@/components/templates/navigation/menuItems'
import {
  canAccessClaimSearch,
  canAccessClaimReport,
  canAccessTaskReport,
  canAccessPaymentReport,
  canAssessEmailTaskDetailsReport,
  canAccessFinancialReports,
  canAccessAuditReports,
  canAccessAnyReport,
  canAccessOptionsAdmin,
  canAccessTaskRagAdmin,
  canAccessMasterDataAdmin,
  canAccessSubclaimAdmin,
  canAccessHolidayAdmin,
  canAccessClassOfBusinessAdmin,
  canAccessClassOfBusinessGroupAdmin,
  canAccessAlertsAdmin,
  canAccessConfigurationAdmin,
  canAccessApprovalLimitsAdmin,
  canAccessWorkflowAdmin,
  canAccessTriageAdmin,
  canAccessEmailTemplatesAdmin,
  canAccessUsersAdmin,
  canAccessRolesAdmin,
  canAccessOrgAdmin,
  canAccessAnyAdministrationPage,
  canAccessAnyEmailInbox,
  canAccessPaymentsAdmin,
  canAccessParticipantsAdmin,
} from '@/services/ability'
import { RouteItems, RouteNames } from '@/router/routes'
import { useConfigurationStore } from '@/stores/configuration/ConfigurationStore'

const topMenuItems = computed(() => {
  const result = []
  const configurationStore = useConfigurationStore()

  result.push(RouteItems.CreateNonBureauClaim)
  result.push(RouteItems.NonBureauTasks)

  if (canAccessAnyEmailInbox()) {
    result.push(RouteItems.EmailInbox)
  }

  if (canAccessClaimSearch()) {
    result.push(RouteItems.Search)
  }

  if (canAccessAnyReport()) {
    result.push(RouteItems.Reporting)
  }

  if (configurationStore.getDOCOinsightsConfiguration?.isEnabled)
    result.push(RouteItems.Docoinsights)

  return result
})

const bottomMenuItems = computed(() => {
  const result = []

  if (canAccessAnyAdministrationPage()) {
    result.push(RouteItems.Administration)
  }

  if (canAccessConfigurationAdmin()) {
    result.push(RouteItems.Configuration)
  }

  return result
})

/**
 *
 */
export function useSidebar() {
  const closedMenuItemsStatus = 'closed'
  const isMobileSidebarOpen = ref(false)
  const activeSubMenuItem = ref(closedMenuItemsStatus)
  const subsiderMenuItems = reactive({ data: [] })
  const isSubsidebarOpen = ref(false)

  const router = useRouter()
  const configurationStore = useConfigurationStore()

  const onCloseSubsiderMenuItems = () => {
    isSubsidebarOpen.value = false
  }

  const toogleMenuItem = (item) => {
    isSubsidebarOpen.value = false
    activeSubMenuItem.value = closedMenuItemsStatus

    const { getMenuItems } = useMenuItems(
      configurationStore.getFeatureConfigurationNames,
      configurationStore.getSystemConfigurationNames
    )

    const accessCheckFunctions = {
      [RouteNames.OptionsConfigurationAdministration]: canAccessOptionsAdmin,
      [RouteNames.RagStatus]: canAccessTaskRagAdmin,
      [RouteNames.ClaimsMasterDataAdministration]: canAccessMasterDataAdmin,
      [RouteNames.ClaimReport]: canAccessClaimReport,
      [RouteNames.FinancialReports]: canAccessFinancialReports,
      [RouteNames.TaskReport]: canAccessTaskReport,
      [RouteNames.Payment]: canAccessPaymentReport,
      [RouteNames.EmailTaskReport]: canAssessEmailTaskDetailsReport,
      [RouteNames.Audit]: canAccessAuditReports,
      [RouteNames.SubclaimsAdministration]: canAccessSubclaimAdmin,
      [RouteNames.HolidayAdministration]: canAccessHolidayAdmin,
      [RouteNames.ClassOfBusinessAdministration]: canAccessClassOfBusinessAdmin,
      [RouteNames.ClassOfBusinessGroupAdministration]: canAccessClassOfBusinessGroupAdmin,
      [RouteNames.AlertAdministration]: canAccessAlertsAdmin,
      [RouteNames.FeatureConfiguration]: canAccessConfigurationAdmin,
      [RouteNames.SystemConfiguration]: canAccessConfigurationAdmin,
      [RouteNames.ApprovalLimitsAdministration]: canAccessApprovalLimitsAdmin,
      [RouteNames.WorkflowAdministration]: canAccessWorkflowAdmin,
      [RouteNames.TriageAdministration]: canAccessTriageAdmin,
      [RouteNames.EmailTemplatesAdministration]: canAccessEmailTemplatesAdmin,
      [RouteNames.UserAdministration]: canAccessUsersAdmin,
      [RouteNames.RoleAdministration]: canAccessRolesAdmin,
      [RouteNames.OrgStructure]: canAccessOrgAdmin,
      [RouteNames.PaymentsAdministration]: canAccessPaymentsAdmin,
      [RouteNames.ParticipantsExpertsAdministration]: canAccessParticipantsAdmin,
    }

    const list = getMenuItems().reduce((acc, x) => {
      if (x.parent !== item) {
        return acc
      }

      if (x.children?.length > 0) {
        const accessibleItems = x.children.filter((child) => {
          const accessCheckFunction = accessCheckFunctions[child.name]

          if (accessCheckFunction) {
            return accessCheckFunction()
          }

          return true
        })

        acc.push({
          ...x,
          children: accessibleItems,
        })

        return acc
      }

      const accessCheckFunction = accessCheckFunctions[x.name]

      if (accessCheckFunction()) {
        acc.push(x)
      }

      return acc
    }, [])

    if (list.length === 0) {
      const currentMenuItem = topMenuItems.value
        .concat(bottomMenuItems)
        .find((x) => x.name === item)

      subsiderMenuItems.data = []
      isMobileSidebarOpen.value = false
      router.push({ name: currentMenuItem.name })
    } else {
      subsiderMenuItems.data = list
      isMobileSidebarOpen.value = true
      isSubsidebarOpen.value = true
    }
  }

  /**
   *
   * @param item
   */
  function toogleSubMenuItem(item) {
    if (activeSubMenuItem.value !== item) {
      activeSubMenuItem.value = item
      router.push({ name: item.name })
    }

    isSubsidebarOpen.value = false
  }

  /**
   *
   * @param item
   */
  function expandSubMenuItem(item) {
    router.push({ name: item.name, query: { id: item.id }, params: { id: item.id } })
    activeSubMenuItem.value = item
    isSubsidebarOpen.value = false
  }

  return {
    topMenuItems,
    isMobileSidebarOpen,
    bottomMenuItems,
    activeSubMenuItem,
    toogleMenuItem,
    subsiderMenuItems,
    isSubsidebarOpen,
    toogleSubMenuItem,
    expandSubMenuItem,
    onCloseSubsiderMenuItems,
  }
}
