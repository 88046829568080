// @ts-check
import axios from 'axios'
import qs from 'qs'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { useApplicationStoreNotPersistant } from '@/stores/ApplicationStoreNotPersistant'
import { useErrorHandler } from '@/services/errors/useErrorHandler'
import { getConfig } from '@/services/useConfig'
import { useLogger } from '@/services/useLogger'

export const useRequest = () => {
  const applicationStore = useApplicationStore()
  const applicationStoreNotPersistant = useApplicationStoreNotPersistant()
  const { handleRequestError, isErrorHandled } = useErrorHandler()

  /**
   * A wrapper around blocking-requests to show the screan blocking overlay.
   * Shows the screen blocking overlay once for all requests to reduce flickering issues.
   * NOTE: Shows the screen blocking overlay AND the spinner.
   * Throws error when any of the promises rejects, with this first rejection reason
   * @param {...Promise} requests
   * @returns {Promise<Array>}
   */
  const requestsWrapper = async (...requests) => {
    let requestId

    try {
      requestId = applicationStoreNotPersistant.setIsRequestRunning()
      return await Promise.all(requests)
    } catch (error) {
      const log = useLogger()
      log.error('useRequest: requestsWrapper', { requestId, error })
      throw error
    } finally {
      applicationStoreNotPersistant.requestFinished(requestId)
    }
  }

  /**
   * A wrapper around blocking-requests to show the screan blocking overlay.
   * Shows the screen blocking overlay once for all requests to reduce flickering issues.
   * Does NOT throw an error if any of the promises rejects.
   * NOTE: Shows the screen blocking overlay WITHOUT the spinner.
   * Implemented specifally for dashboard screen, since each chart has its own spinner there.
   * @param {...Promise} requests
   * @returns {Promise<Array>}
   */
  const requestsWrapperWithoutSpinner = async (...requests) => {
    let requestId

    try {
      requestId = applicationStoreNotPersistant.setIsRequestRunning(false)
      return await Promise.all(requests)
    } catch (error) {
      const log = useLogger()
      log.error('useRequest: requestsWrapperWithoutSpinner', { requestId, error })
    } finally {
      applicationStoreNotPersistant.requestFinished(requestId)
    }
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @param {string} [errorMesssage]
   * @param {string} [contentType]
   * @param {Record<string, string | number | boolean>} [params]
   * @param {boolean} [asBackgroundRequest]
   * @param {AbortSignal} [abortSignal]
   * @returns {Promise<any | undefined>}
   */
  const post = async (
    url,
    data,
    throwError,
    handleError,
    errorMesssage,
    contentType,
    params,
    asBackgroundRequest = false,
    abortSignal
  ) => {
    const callback = () =>
      axios.post(url, data, {
        headers: contentType && {
          'Content-Type': contentType,
        },
        signal: abortSignal,
        params,
      })

    if (asBackgroundRequest)
      return await makeBackgroundRequest(callback, url, throwError, handleError)
    return await makeRequest(callback, url, throwError, handleError, errorMesssage)
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @param {boolean} [throwError]
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const patch = async (url, data, throwError, asBackgroundRequest = false) => {
    const callback = () => axios.patch(url, data)

    if (asBackgroundRequest) return await makeBackgroundRequest(callback, url, throwError)
    return await makeRequest(callback, url, throwError)
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @param {boolean} [throwError]
   * @param {string} [contentType]
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const put = async (url, data, throwError, contentType, asBackgroundRequest = false) => {
    const callback = () =>
      axios.put(
        url,
        data,
        contentType && {
          headers: {
            'Content-Type': contentType,
          },
        }
      )

    if (asBackgroundRequest) return await makeBackgroundRequest(callback, url, throwError)
    return await makeRequest(callback, url, throwError)
  }

  /**
   *
   * @param {string} url
   * @param {any} [params]
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @param {boolean} [asBackgroundRequest]
   * @param {AbortSignal} [abortSignal]
   * @returns {Promise<any | undefined>}
   */
  const get = async (
    url,
    params = null,
    throwError,
    handleError,
    asBackgroundRequest = false,
    abortSignal
  ) => {
    const callback = () =>
      axios.get(url, {
        params: params,
        paramsSerializer: {
          serialize: (params) => {
            if (params) {
              const result = qs.stringify(params, { allowDots: true })
              return result
            }
          },
        },
        signal: abortSignal,
      })

    if (asBackgroundRequest)
      return await makeBackgroundRequest(callback, url, throwError, handleError)
    return await makeRequest(callback, url, throwError, handleError)
  }

  /**
   *
   * @param {string} url
   * @param {boolean} throwError
   * @param {any} [params]
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const del = async (
    url,
    throwError = true,
    params = null,
    asBackgroundRequest = false
  ) => {
    const callback = () =>
      axios.delete(url, {
        params: params,
        paramsSerializer: {
          serialize: (params) => {
            if (params) {
              const result = qs.stringify(params, { allowDots: true })
              return result
            }
          },
        },
      })

    if (asBackgroundRequest) return await makeBackgroundRequest(callback, url)
    return await makeRequest(callback, url, throwError)
  }

  /**
   *
   * @param {string } url
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const download = async (url, asBackgroundRequest = false) => {
    const callback = () =>
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      })

    if (asBackgroundRequest) return await makeBackgroundRequest(callback, url)
    return await makeRequest(callback, url)
  }

  /**
   *
   * @param {Function} callback
   * @param {string} url
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @param {string} [errorMesssage]
   * @returns {Promise<any | undefined>} The response data if succesfull
   */
  const makeRequest = async (
    callback,
    url,
    throwError = true,
    handleError = true,
    errorMesssage = ''
  ) => {
    let requestId

    try {
      const config = getConfig()
      const log = useLogger()

      axios.defaults.baseURL = config.api.url
      requestId = applicationStoreNotPersistant.setIsRequestRunning()

      log.info('makeRequest', { url, requestId })

      const token = applicationStore.accessToken
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      const response = await callback()
      return response.data
    } catch (error) {
      if (handleError) {
        await handleRequestError({ error }, errorMesssage)
        error.isHandled = isErrorHandled({ error })
      }

      if (throwError) throw error
    } finally {
      applicationStoreNotPersistant.requestFinished(requestId)
    }
  }

  /**
   *
   * @param {Function} callback
   * @param {string} url
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @returns {Promise<any | undefined>} The response data if succesfull
   */
  const makeBackgroundRequest = async (callback, url, throwError, handleError) => {
    const log = useLogger()

    try {
      const config = getConfig()

      axios.defaults.baseURL = config.api.url
      log.info('Background request', url)

      const token = applicationStore.accessToken
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      const response = await callback()
      return response.data
    } catch (error) {
      log.error('Background request error', error)

      if (handleError) {
        await handleRequestError({ error })
        error.isHandled = isErrorHandled({ error })
      }

      if (throwError) throw error
    }
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @returns {Promise<any | undefined>} The response data if succesfull
   */
  const backgroundPostRequest = async (url, data) => {
    const config = getConfig()
    const log = useLogger()

    axios.defaults.baseURL = config.api.url
    log.info('Background post request', url)

    const token = applicationStore.accessToken
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const response = await axios.post(url, data)
    return response.data
  }

  return {
    post,
    get,
    download,
    put,
    patch,
    del,
    requestsWrapper,
    requestsWrapperWithoutSpinner,
    backgroundPostRequest,
  }
}
